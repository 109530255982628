@import "./base/variables";

.mf{
  &-page{
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    background-color: $white;
  }

  &-btn {
    width: auto;
    text-align: center;
    outline: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    min-height: 55px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px 25px;
    border-radius: 4px;
    background-color: #EF6F21;
    color: $white;
    font-size: 20px;
    font-weight: 400;
    transition: background-color .25s linear;

    &--disabled {
      pointer-events: none;
      background-color: #C4C4C4;
    }

    &:hover {
      background-color: $orange-400;
    }
    @media (max-width: 910px) {
      min-height: 51px;
      font-size: 16px;
    }
  }

  &-hero{
    width: 100%;
    min-height: 55.5vw;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 600px) {
      min-height: calc(100vh - 64px);
    }
    &__bg{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($blue-1, .7);
    }
    &__img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__data{
      position: relative;
      z-index: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    &__description{
      width: 100%;
      max-width: 850px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (max-width: 600px) {
        max-width: 312px;
      }
    }
    &__title{
      font-family: $font-secondary;
      font-weight: bold;
      font-size: 50px;
      color: $white;
      line-height: 64px;
      margin: 0;
      @media (max-width: 910px) {
        font-size: 28px;
        line-height: 34px;
      }
    }
    &__about{
      font-size: 20px;
      color: $white;
      line-height: 1.4;
      margin: 0;
      @media (max-width: 910px) {
        font-size: 16px;
      }
    }
  }

  &-complex{
    background-color: $white;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 34.8vw;
    &__inner{
      width: 100%;
      max-width: 1392px;
      margin: auto;
      box-sizing: border-box;
      padding: 20px 16px;
      display: flex;
      gap: 30px;
      align-items: center;
      position: relative;
      z-index: 1;
      @media (max-width: 600px) {
        padding: 60px 16px 0;
      }
    }
    &__data{
      align-self: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 600px;
      gap: 10px;
      @media (max-width: 910px) {
        max-width: 416px;
      }
      @media (max-width: 600px) {
        max-width: 275px;
      }
    }
    &__title{
      font-family: $font-secondary;
      font-weight: bold;
      font-size: 40px;
      color: $gray-800;
      line-height: 56px;
      margin: 0;
      @media (max-width: 910px) {
        font-size: 28px;
        line-height: 38px
      }
    }
    &__about{
      font-size: 20px;
      color: $gray-800;
      line-height: 1.4;
      margin: 0;
      @media (max-width: 910px) {
        font-size: 16px;
      }
    }
    &__bg{
      position: absolute;
      right: 0;
      top: 0;
      line-height: 0;
      height: 100%;
      width: 67.3%;
      @media (max-width: 600px) {
        position: static;
        width: 100%;
        height: auto;
      }
    }
    &__img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
  }

  &-team{
    display: flex;
    position: relative;
    min-height: 32.9vw;
    background-color: $blue-1;
    @media (max-width: 600px) {
      min-height: 0;
      flex-direction: column;
    }
    &__inner{
      width: 100%;
      max-width: 1114px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 20px 16px;
      display: flex;
      gap: 70px;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      z-index: 1;
      @media (max-width: 600px) {
        justify-content: flex-start;
        padding: 60px 16px 24px;
      }
    }
    &__data{
      align-self: center;
      display: flex;
      flex-direction: column;
      width: calc(50% - 20px);
      max-width: 472px;
      gap: 10px;
      @media (max-width: 600px) {
        width: 100%;
        max-width: 320px;
      }
    }
    &__title{
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 40px;
      color: #1F2937;
      line-height: 56px;
      margin: 0 auto;
      position: relative;
      width: auto;
      @media (max-width: 910px) {
        font-size: 28px;
        line-height: 38px
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: $orange;
      }
    }
    &__title-2{
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 40px;
      color: $white;
      line-height: 56px;
      @media (max-width: 910px) {
        font-size: 28px;
        line-height: 38px
      }
    }
    &__about{
      font-size: 20px;
      color: $white;
      line-height: 1.4;
      margin: 0;
      @media (max-width: 910px) {
        font-size: 16px;
      }
    }
    &__bg{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 50%;
      line-height: 0;
      @media (max-width: 600px) {
        position: static;
        width: 100%;
        height: auto;
      }
    }
    &__img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left top;
    }
  }

  &-program{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 62vw;
    background-position: center top;
    background-size: cover;
    box-sizing: border-box;
    padding: 120px 16px 60px;
    @media (max-width: 910px) {
      padding-top: 60px;
    }
    &__inner{
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 4.8rem;
      @media (max-width: 600px) {
        gap: 2.4rem;
      }
    }
    &__description{
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      gap: 10px;
      @media (max-width: 600px) {
        text-align: left;
      }
    }
  }

  &-list{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    &-wrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    &-title{
      font-family: $font-secondary;
      font-weight: bold;
      font-size: 32px;
      color: $blue-1;
      line-height: 42px;
      margin: 0;
      text-align: center;
      @media (max-width: 910px) {
        font-size: 24px;
        line-height: 32px
      }
    }
  }
  &-co{
    width: 100%;
    background-color: $white;
    &__head{
      cursor: pointer;
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 24px 30px;
      @media (max-width: 910px) {
        padding: 20px 16px;
      }
      &:after{
        content: '\e90d';
        font-family: icomoon;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: bold;
        color: $blue-1;
        width: 24px;
        height: 24px;
        line-height: 1;
        transition: transform .2s linear;
      }

      &.open{
        .mf-co__title{
          color: #EF6F21;
        }
        &:after{
          transform: rotate(90deg);
        }
      }
    }
    &__title{
      font-size: 26px;
      font-weight: bold;
      color: $blue-1;
      transition: color .2s linear;
      @media (max-width: 910px) {
        font-size: 20px;
      }
    }
    &__body{
      display: none;
      &-inner{
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 30px 24px 50px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media (max-width: 910px) {
          padding: 0 11px 16px;
          gap: 10px;
        }
      }
    }
    &__li{
      display: flex;
      align-items: flex-start;
      gap: 10px;
      color: $blue-1;
      font-size: 20px;
      @media (max-width: 910px) {
        font-size: 16px;
      }
      &:before{
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $blue-1;
        position: relative;
        top: 7px;
      }
    }

  }

  &-pricing{
    box-sizing: border-box;
    width: 100%;
    padding: 120px 0 124px;
    background-color: $white;
    @media (max-width: 1199px) {
      padding-left: 16px
    }
    @media (max-width: 910px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    &__inner{
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
      margin: 0 auto;
    }
    &__slider{
      width: 100%;
      display: flex;
      gap: 24px;
      &:not(.slick-slider){
        padding: 32px 0;
      }
      @media (max-width: 1199px) {
        gap: 10px;
      }

      .slick-track{
        display: flex;
        gap: 24px;
        padding: 32px 0;
        @media (max-width: 1199px) {
          gap: 10px;
        }
      }
    }

    .mf-slider{
      &-controls{
        align-self: flex-start;
        @media (min-width: 1200px) {
          padding-left: 16px;
        }
      }
      &-nav{
        border-color: #D6D6D6;
        &:before{
          color: $blue-1;
        }
        &:hover{
          @media (min-width: 910px) {
            background-color: rgba($gray-300, .2);
          }
        }
      }
    }
  }

  &-slide{
    width: calc(100% / 3 - 16px);
    display: flex;
    flex-direction: column;
    gap: 30px;
    box-sizing: border-box;
    padding: 24px 36px;
    background-color: $gray-bg;
    border-radius: 4px;
    @media (max-width: 1199px) {
      width: 308px;
      gap: 16px;
    }
    &__title{
      text-align: center;
      font-size: 33px;
      color: $gray-800;
      font-weight: bold;
      line-height: 1.1;
      @media (max-width: 1199px) {
        font-size: 24px
      }
    }
    &__price{
      text-align: center;
      font-size: 28px;
      color: $gray-800;
      line-height: 1.1;
      @media (max-width: 1199px) {
        font-size: 22px
      }
    }
    &__btn {
      width: 100%;
      text-align: center;
      outline: none;
      text-decoration: none;
      cursor: pointer;
      min-height: 56px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 5px;
      border-radius: 4px;
      background-color: transparent;
      color: $blue-1;
      border: 2px solid $blue-1;
      font-size: 16px;
      font-weight: bold;
      transition: background-color .25s linear;

      &:hover {
        background-color: rgba($blue-1, .2);
      }
    }

    &__ul{
      list-style-type: none;
      background-color: transparent !important;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &__li{
      font-size: 16px;
      line-height: 1.5;
      color: $gray-800 !important;
      background-color: transparent;
      width: auto;
      display: inline-flex;
      align-items: flex-start;
      gap: 17px;
      @media (max-width: 1199px) {
        gap: 12px;
      }
      &:before{
        content: '\e92e';
        font-family: icomoon;
        font-size: 15px;
        color: $blue-1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #E8EDFB;
      }
    }
    &--main{
      background-color: $blue-1;
      &.slick-active{
        box-shadow: 0 10px 25px 0 #CCD9FF;
      }
      .mf-slide{
        &__title, &__price, &__btn{
          color: $white;
        }
        &__li{
          color: $white !important;
        }
        &__btn{
          border: none;
          background-color: #EF6F21;
          &:hover {
            background-color: $orange-400;
          }
        }
      }
    }
  }

  &-faq{
    width: 100%;
    box-sizing: border-box;
    padding: 120px 16px;
    background-color: $gray-bg;
    @media (max-width: 910px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    &__inner{
      width: 100%;
      max-width: 720px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  }

  &-qa{
    width: 100%;
    background-color: $white;
    &__head{
      cursor: pointer;
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 18px 24px;
      &:after{
        content: '\e90d';
        font-family: icomoon;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-weight: bold;
        color: $blue-1;
        width: 16px;
        height: 16px;
        line-height: 1;
        transition: transform .2s linear;
      }

      &.open{
        &:after{
          transform: rotate(90deg);
        }
      }
    }
    &__title{
      font-size: 26px;
      font-weight: 400;
      color: $gray-800;
      transition: color .2s linear;
      line-height: 31.2px;
      @media (max-width: 990px) {
        font-size: 20px;
      }
    }
    &__body{
      font-size: 16px;
      display: none;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 24px 18px;
      color: $gray-800;
      line-height: 1.4;
    }

  }

  &-testimonials{
    width: 100%;
    box-sizing: border-box;
    padding: 120px 0;
    //background-color: $blue-1;
    @media (max-width: 1199px) {
      padding-left: 16px;
    }
    @media (max-width: 910px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    &__inner{
      width: 100%;
      max-width: 1360px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 54px;
      @media (max-width: 910px) {
        gap: 20px;
      }
    }
    &__slider{
      width: 100%;
      display: flex;
      gap: 20px;
      @media (max-width: 1199px) {
        gap: 10px;
      }
      .slick-track{
        display: flex;
        gap: 20px;
        @media (max-width: 1199px) {
          gap: 10px;
        }
      }
      &.dst-card {
        margin: 0;
      }
    }
  }

  &-quote-slide{
    width: calc(100% / 3 - 14px);
    //background-color: $white;
    box-sizing: border-box;
    //padding: 36px 26px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    position: relative;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
    background-color: $blue-3;
    padding: 44px 32px;
    cursor: pointer;
    min-height: 368px;
    min-width: 409px;
    @media (max-width: 1199px) {
      width: 310px;
      padding: 20px 26px;
    }
    &__about{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-size: 18px;
      line-height: 1.45;
      @media (max-width: 1199px) {
        font-size: 16px;
        gap: 12px;
      }
      &:before{
        content: '';
        display: inline-block;
        width: 42px;
        height: 34px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 42 34' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 33.3594H0.359375V16.6406C0.359375 6.82291 5.90625 1.27604 17 0V8.35937C11.6354 8.54166 8.84896 11.3021 8.64062 16.6406H17V33.3594ZM42 33.3594H25.3594V16.6406C25.3594 6.82291 30.9062 1.27604 42 0V8.35937C36.6354 8.54166 33.849 11.3021 33.6406 16.6406H42V33.3594Z' fill='%23CCDDE5'/%3E%3C/svg%3E ");
      }
    }
    &__main{
      display: flex;
      gap: 14px;
      margin-top: auto;
    }
    &__img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      &-wrapper{
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    //&__data{
    //  display: flex;
    //  flex-direction: column;
    //  gap: 4px;
    //}
    &__data {
      position: relative;
      align-self: center;

      &-image {
        max-width: 84px;
        width: 100%;
        max-height: 84px;
        &--sm{
          max-height: 45px;
          max-width: 145px;
        }

        &-wrapper {
          margin-bottom: 24px;
          height: 84px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
    }
    &__author{
      font-size: 18px;
    }
    &__post{
      font-size: 14px;
      color: #AFB1B6;
    }
    &__image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: .4;
    }
    &__text p{
      color: $white;
      font-size: 16px;
    }
  }

  &-slider{
    &-controls{
      display: inline-flex;
      gap: 9px;
      @media (max-width: 1199px) {
        align-self: flex-start;
      }
    }
    &-nav{
      width: 40px;
      min-width: 40px;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid rgba($white, .2);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      cursor: pointer;
      transition: background-color .2s linear;
      &:before{
        content: '\e952';
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: icomoon;
        font-size: 10px;
        line-height: 1;
        color: $white;
        width: 12px;
        height: 12px;
      }
      &:hover{
        @media (min-width: 910px) {
          background-color: rgba($white, .2);
        }
      }
      &--prev:before{
        transform: rotate(90deg);
      }
      &--next:before{
        transform: rotate(-90deg);
      }
    }
  }

  &-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 16px;
    z-index: 99;
    &:before{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black-3, .4);
      backdrop-filter: blur(2px);
    }
    &:not(.open){
      display: none;
    }

    &-content{
      width: 100%;
      max-width: 560px;
      position: relative;
      z-index: 1;
      border-radius: 4px;
      background-color: $white;
      box-sizing: border-box;
      padding: 80px 30px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-height: 100%;
      overflow: auto;
      @media (max-width: 600px) {
        padding: 40px 16px 24px;
        gap: 16px;
      }
    }
    &-wrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
    &-close{
      width: 24px;
      height: 24px;
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 22px;
      font-style: normal;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $gray-400-2;
      transition: color .2s linear;
      cursor: pointer;
      &:hover{
        color: $gray-800;
      }
    }
    &-title{
      width: 100%;
      max-width: 305px;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.4;
      text-align: center;
      margin: 0 auto;
    }
    &-form{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6px;
      &__label{
        display: inline-block;
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        color: $gray-700;
      }
      &__input, &__select .drd-select__value{
        width: 100%;
        height: 41px !important;
        min-height: 41px;
        background-color: transparent !important;
        padding: 0 14px !important;
        font-size: 14px;
        color: $gray-700;
        border-radius: 6px;
        border: 1px solid $gray-300;
        outline: none;
        box-shadow: none !important;
        &.error{
          border-color: $red-500;
        }
      }
      &__select{
        .drd-select{
          &__value{
            &-data{
              font-size: 16px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: calc(100% - 16px);
              display: block;
            }
          }
          &__item{
            color: $gray-700;
            &:after{
              display: none !important;
            }
          }

        }
      }
      &__error{
        font-size: 12px;
        color: $red-500;
      }
    }
    &-description{
      width: 100%;
      max-width: 449px;
      margin: 0 auto;
      text-align: center;
      font-size: 12px;
      color: $gray-400-2;
    }
    &-btn{
      height: 45px;
      padding: 0 25px;
      font-size: 16px;
      &-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
      }
    }
  }
}
